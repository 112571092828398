<template>
  <div>
    <b-modal modal-class="modal_basic modal_basic_seo_preview modal-seo-preview" :id="componentId" hide-footer
             hide-header>

      <div class="heading">
        <h3 class="d-inline-block title">
          <template>
            Preview
          </template>
        </h3>
        <img @click="$bvModal.hide(componentId)" class="close_modal" src="/assets/img/cross.svg" alt="">
      </div>
      <div class="text-center">
        <img :src="url ? url : '/assets/img/notfound.png'">
      </div>
    </b-modal>
    <div class="input_icon_text mt-3 profile__box">
      <div class="content">
        <div class="url">
          <div class="avatar mr-1">
            <i class="fas fa-image"></i>
          </div>
          {{ title }}

          <div v-if="tooltip" class="avatar ml-1">
            <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
              <div class="tool_tip_box">
                <div class="inner_box">
                  <p style="word-break: normal">{{ tooltip }}</p>
                </div>
              </div>
            </i>
          </div>

        </div>
      </div>
      <div class="mt-2 d-flex align-items-center profile__box">
        <div class="content">
          <div class="url">{{ urlTitle }}</div>
          <div class="name">
            <div class="validation_input">
              <url
                v-model="url"
                :validations="URLValidations"
                v-bind:blur="() => url = focusoutConcatenation(url)"
                :ref="componentId"
                data-cy="b-url"
                :placeholder="placeholder"></url>
            </div>
          </div>
        </div>
      </div>
      <input
        :id="componentId"
        placeholder="Enter the favicon image url (16 x 16 recommended)"
        type="file"
        @change="uploadImage($event)"
        accept=".png, .jpg, .jpeg, .gif, .ico"
        class="form-control txt-left-left d-none">
      <label :for="componentId" class="upload-meta-image text-center mt-3">
        <i class="fas fa-image" v-if="!uploadLoader" aria-hidden="true"></i>
        <clip-loader v-else class="loader" :size="'14px'" :color="'#2d99ec'"></clip-loader>
        <span>Upload Image</span>
        <span class="text-center text-muted text-xs" v-if="recomendedSize"><br>{{ recomendedSize }}</span>
      </label>
      <div v-if="!!isImagePreviewEnabled && url"  class="upload-meta-preview mt-3 d-inline-block"
           @click="$bvModal.show(componentId)">
        <i class="fa fa-eye" aria-hidden="true"></i>
        <span>Preview</span>
      </div>
    </div>
    <span class="input-error" v-if="URLValidations.url">{{ URLMessages.url }}</span>
    <span class="input-error" v-else-if="URLValidations.urlValid">{{ URLMessages.urlValid }}</span>
    <span class="input-error" v-else-if="URLValidations.urlLength">{{ URLMessages.urlLength }}</span>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {URLMessages} from "@/common/attributes";

export default ({
  data () {
    return {
      uploadLoader: false,
      url: '',
      URLMessages: URLMessages,
      URLValidations: {},
      componentId: ''
    }
  },
  components: {
    'url': () => import('@/ui/URL')
  },
  created () {
    this.URLValidations = this.validations
    this.url = this.imageUrl
    this.componentId = this.id ? this.id : 'upload_image_or_url'
  },
  props: [
    'title',
    'urlTitle',
    'placeholder',
    'imageUrl',
    'isImagePreviewEnabled',
    'validations',
    'id',
    'tooltip',
    'recomendedSize'
  ],
  watch: {
    'url' (value) {
      this.$emit('uploadImage', value)
    }
  },
  methods: {
    ...mapActions(['uploadPasswordProtectedLogo']),
    async uploadImage (event) {
      this.uploadLoader = true
      this.url = await this.uploadPasswordProtectedLogo(event)
      this.checkURL()
      this.uploadLoader = false
      this.$emit('uploadImage', this.url)
    },
    checkURL () {
      if (this.url && this.url.length > 8) {
        this.validations.url = this.requiredCheck(this.url)
        this.url = this.urlConcatenation(this.url)
        this.validations.urlLength = !this.maxLength(this.url, 500)
        this.validations.urlValid  = !this.isValidURL(this.url)
      }
    },
    checkValidation () {

      if(this.uploadLoader)  {
        this.$store.dispatch('toastNotification', {
          message: 'Please wait your image is uploading...',
          type: 'error'
        })
        return true
      }

      if (this.url && this.url.length) {
        this.url = this.urlConcatenation(this.url)
        this.URLValidations.urlValid = !this.isValidURL(this.url)
        if(!Object.keys(this.URLValidations).every(k => this.URLValidations[k] === false)) {
          this.$store.dispatch('toastNotification', {
            message: 'Please enter valid Image url!',
            type: 'error'
          })
          return true
        }
      }
      return false
    }
  },
})
</script>
